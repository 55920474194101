import { useTranslation } from 'react-i18next';

import Facebook from '@/assets/icons/facebook.svg';
import Instagram from '@/assets/icons/instagram.svg';
import LinkedIn from '@/assets/icons/linkedin.svg';
import Twitter from '@/assets/icons/twitter.svg';
import Casavo from '@/assets/svg/casavo.svg';
import CustomLink, {
  TargetLink
} from '@/components/common/customLink/CustomLink';
import {
  URL_FACEBOOK,
  URL_INSTAGRAM,
  URL_LINKEDIN,
  URL_TWITTER,
  URLS
} from '@/constants/global';
import { Container } from '@proprioo/salatim';

import {
  Bottom,
  Content,
  CookieButton,
  Legal,
  Links,
  LinksCategories,
  LinksList,
  LogoSocials,
  LogoWrapper,
  Round,
  Socials,
  SocialWrapper
} from './Footer.styles';
import FooterLinks from './footerLinks/FooterLinks';

const SocialsLink = (
  <Socials>
    <Round
      aria-label="casavoLinkedinLink"
      href={URL_LINKEDIN}
      id="footer-link-linkedin"
      rel="noreferrer noopener"
      target="_blank"
      title="casavo sur linkedin"
    >
      <LinkedIn />
    </Round>
    <Round
      aria-label="casavoTwitterLink"
      href={URL_TWITTER}
      id="footer-link-twitter"
      rel="noreferrer noopener"
      target="_blank"
      title="casavo sur twitter"
    >
      <Twitter />
    </Round>
    <Round
      aria-label="casavoInstagramLink"
      href={URL_INSTAGRAM}
      id="footer-link-instagram"
      rel="noreferrer noopener"
      target="_blank"
      title="casavo sur instagram"
    >
      <Instagram />
    </Round>
    <Round
      aria-label="casavoFacebookLink"
      href={URL_FACEBOOK}
      id="footer-link-facebook"
      rel="noreferrer noopener"
      target="_blank"
      title="casavo sur facebook"
    >
      <Facebook />
    </Round>
  </Socials>
);

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Links data-test="links">
      <Container>
        <Content>
          <LogoSocials>
            <LogoWrapper>
              <CustomLink link={URLS.HOMEPAGE}>
                <Casavo />
              </CustomLink>
            </LogoWrapper>
          </LogoSocials>
          <LinksCategories>
            <LinksList>
              <CustomLink link={URLS.SEARCH} id="footer-link-alert">
                {t(URLS.SEARCH.label)}
              </CustomLink>
            </LinksList>
            <LinksList>
              <CustomLink link={URLS.ALERT} id="footer-link-alert">
                {t(URLS.ALERT.label)}
              </CustomLink>
            </LinksList>
            <LinksList>
              <FooterLinks label={t('aboutUs')}>
                <CustomLink link={URLS.ABOUT} id="footer-link-about-us">
                  {t(URLS.ABOUT.label)}
                </CustomLink>
                <CustomLink
                  link={URLS.JOIN_US}
                  id="footer-link-join-us"
                  target={TargetLink.BLANK}
                >
                  {t(URLS.JOIN_US.label)}
                </CustomLink>
                <CustomLink
                  link={URLS.SMART_RECRUITERS}
                  id="footer-link-be-agent"
                  target={TargetLink.BLANK}
                >
                  {t('beAdvisor')}
                </CustomLink>
              </FooterLinks>
            </LinksList>
            <LinksList>
              <FooterLinks label={t('resources')}>
                <CustomLink link={URLS.SELLER_GUIDE} id="footer-link-guide">
                  {t(URLS.SELLER_GUIDE.label)}
                </CustomLink>
                <CustomLink
                  link={URLS.BLOG}
                  id="footer-link-blog"
                  target={TargetLink.BLANK}
                >
                  {t(URLS.BLOG.label)}
                </CustomLink>
                <CustomLink
                  link={URLS.FAQ}
                  id="footer-link-faq"
                  target={TargetLink.BLANK}
                >
                  {t(URLS.FAQ.label)}
                </CustomLink>
              </FooterLinks>
            </LinksList>
          </LinksCategories>
          <SocialWrapper>{SocialsLink}</SocialWrapper>
        </Content>
        <Bottom>
          <Legal>
            <CustomLink link={URLS.LEGAL}>{t(URLS.LEGAL.label)}</CustomLink>
            <CustomLink link={URLS.PRIVACY}>{t(URLS.PRIVACY.label)}</CustomLink>
            <CookieButton
              onClick={() =>
                window.openAxeptioCookies && window.openAxeptioCookies()
              }
            >
              {t('cookiesSettings')}
            </CookieButton>
            <CustomLink link={URLS.LEGAL_COOKIES} id="footer-link-cookie">
              {t(URLS.LEGAL_COOKIES.label)}
            </CustomLink>
            <CustomLink link={URLS.MEDIA} id="footer-link-media">
              {t(URLS.MEDIA.label)}
            </CustomLink>
            <CustomLink link={URLS.FEES} id="footer-link-fees">
              {t(URLS.FEES.label)}
            </CustomLink>
          </Legal>
        </Bottom>
      </Container>
    </Links>
  );
};

export default Footer;
